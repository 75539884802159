<template>
    <div class="card title">
        <div class="card-body title-body p-3">
            <h3 class="tile-title is-line mb-2">
                Thiết bị của tôi
            </h3>
            <div class="row">
                <div class="col-12">
                    <g-loading v-if="firstLoading" />
                    <div v-else class="title-template-table  table-responsive c-config-content">
                        <h4 class="c-config-content__title pt-1">Thiết bị đang hoạt động</h4>
                        <p>Đây là thông tin thiết bị bạn đang dùng để đăng nhập vào tài khoản trên hệ thống</p>
                        <div v-if="!params.current" class="text-center text-primary">
                            <b-spinner></b-spinner>
                        </div>
                        <div v-else class="card border-info-color">
                            <h6 class="card-header bg-info-ligh h-text-weight-400">{{params.current.ua}}</h6>
                            <div class="card-body">
                                <form class="form-horizontal is-label-right">
                                    <div class="form-group row">
                                        <label class="col-form-label col-md-3">Địa chỉ IP</label>
                                        <div class="col-md-6">
                                            <span class="form-control-plaintext text-blue">{{ params.current.ip }}</span>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-form-label col-md-3">Thiết bị</label>
                                        <div class="col-md-6">
                                            <span class="form-control-plaintext text-blue">{{getDeviceName(params.current.ua)}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-form-label col-md-3">Đăng nhập lúc</label>
                                        <div class="col-md-6">
                                            <span class="form-control-plaintext text-blue">{{params.current.create_time * 1000 | datetimefullFormat}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-form-label col-md-3">Hoạt động cuối lúc</label>
                                        <div class="col-md-6">
                                            <span class="form-control-plaintext text-blue">{{params.current.last_request_time * 1000 | datetimefullFormat}}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="title-template-table  table-responsive c-config-content">
                        <h4 class="c-config-content__title pt-1">Lịch sử thiết bị</h4>
                        <p>Đây là lịch sử các thiết bị bạn đã dùng để đăng nhập. Bạn có thể xem mình đã đăng nhập vào hệ thống bằng thiết bị nào, khi nào và ở đâu. Nếu bạn nhận thấy có bất kì hoạt động bất thường ở thiết bị nào, hãy xoá thiết bị đó và đổi mật khẩu</p>
                        <p>Vì lý do an ninh, những thông tin này cũng được hiển thị cho quản trị viên</p>
                        <div v-if="listLoading" class="text-center text-primary">
                            <b-spinner></b-spinner>
                        </div>
                        <div v-else>
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Thiết bị</th>
                                        <th class="text-center">Địa chỉ IP</th>
                                        <th class="text-center">Đăng nhập lúc</th>
                                        <th class="text-center">Hoạt động cuối lúc</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items" :key="item.id" >
                                        <td>
                                            <span :class="((Date.now() - item.last_request_time * 1000) <= 60 * 15 * 1000 ? 'c-signal is-success' : 'c-signal')">
                                                <span class="h-word-break">{{getDeviceName(item.ua)}}</span>
                                            </span>
                                        </td>
                                        <td class="text-center">{{item.ip}}</td>
                                        <td class="text-center">{{item.create_time * 1000 | datetimefullFormat}}</td>
                                        <td class="text-center">{{item.last_request_time * 1000 | datetimefullFormat}}</td>
                                        <td class="text-center">
                                <g-button v-if="params.current.id !== item.id" :loading="delLoading" size="xs" variant="outline-danger" class="ml-1" icon="trash-fill" @click='del(item.id)' />
                                </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="row" v-if="itemCount > filterFormModel.size">
                                <div class="col-lg-6 form-inline">
                                    Đang xem {{firstItemOfPage}}-{{lastItemOfPage}}/{{itemCount}} thiết bị
                                </div>
                                <div class="col-lg-6">
                                    <b-pagination size="sm" class="pagination pagination-sm float-right pb-0 mb-0"
                                                  v-model="filterFormModel.page" :per-page="filterFormModel.size" :total-rows="itemCount" @change="setPage">
                                    </b-pagination>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import parser from 'ua-parser-js';
    import list from '@/mixins/List';
    import curd from '@/mixins/CRUD';

    export default {
        mixins: [list, curd],
        data: function () {
            return {
                formModel: {},
                defaultFilterFormModel: {
                    size: 10,
                    token: this.$store.state.token
                },
            };
        },
        methods: {
            load() {
                let loadingDelay = _.delay(() => {
                    this.listLoading = true;
                }, 250);

                let filter = Object.assign({}, this.filterFormModel);
                Object.keys(filter).forEach(key => {
                    if (typeof filter[key] === 'object' && filter[key]) {
                        filter[key] = filter[key].join(",");
                    }
                });

                this.$service.get('/site/device', {
                    params: filter
                }).then(response => {
                    this.firstLoading = false;
                    this.apply(response.data);
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    clearTimeout(loadingDelay);
                    this.listLoading = false;
                });
            },
            del: function (id) {
                this.$swal({
                    title: "Bạn chắc chắn muốn xóa chứ?",
                    text: "Sẽ không thể khôi phục lại dữ liệu đã xóa!",
                    icon: "warning",
                    showCancelButton: true
                }).then((result) => {
                    if (result.value) {
                        this.delLoading = true;
                        this.$service.delete(`/site/device-del?id=${id}`).then(response => {
                            if (response.data) {
                                if (this.load) {
                                    this.load();
                                }
                                if (this.reloadBus) {
                                    this.$bus.$emit(this.reloadBus);
                                }
                                this.$swal({
                                    title: "Thành công",
                                    text: "Xoá thành công",
                                    icon: 'success',
                                    toast: true,
                                    position: 'bottom-end',
                                    timer: 5000,
                                });
                            } else {
                                this.$swal({
                                    title: "Có lỗi",
                                    text: "Xoá không thành công",
                                    icon: 'error',
                                    toast: true,
                                    position: 'bottom-end',
                                    timer: 5000,
                                });
                            }
                        }).catch(error => {
                            if (error.response.data.message) {
                                this.$swal({
                                    text: error.response.data.message,
                                    icon: 'error'
                                });
                            }
                        }).finally(() => {
                            this.delLoading = false;
                        });
                    }
                });
            },
            getDeviceName: function (userAgent) {
                let uaInfo = new parser(userAgent);
                return uaInfo.getOS().name + ' ' + uaInfo.getOS().version + ', ' + uaInfo.getBrowser().name;
            }
        },
    }
</script>